var $class="se2--footer-se",$name="FooterSE",$path="app/components/FooterSE/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import 'app/partials/captcha';
import 'app/partials/checkbox';
import 'app/partials/embeddedChat';
import 'app/partials/input';
import 'app/partials/image';
import 'app/partials/notification';
import 'app/partials/select';
import 'app/partials/submit';
import 'app/partials/snackbarComponent/';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';

import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';

import './partials/bottomBar';
import './partials/footerLinks';
import './partials/subscribeForm';

export default shell.registerComponent($this, (
  { addEventListener,
    configs,
    mount },
) => {
  mount((element) => {
    const footerTopClassName = 'footer-top';
    const emptyLinkBlockSelector = '.empty-block';
    const linksColumnSelector = '.links-column';
    const emptyLinkBlocksArray = element.querySelectorAll(emptyLinkBlockSelector);
    const linkColumnsArray = element.querySelectorAll(linksColumnSelector);
    forEach(emptyLinkBlocksArray, (block) => block.remove());
    forEach(linkColumnsArray, (column) => {
      if (isEqual(column.childElementCount, 0)) column.classList.add(configs.css.classNames.hide);
    });

    const announceFooterHeight = () => {
      element.classList.remove(footerTopClassName);
      if (isMobileBreakpoint()) return;
      if (
        Math.round(element.getBoundingClientRect().bottom)
        < document.documentElement.getBoundingClientRect().height
      ) {
        element.classList.add(footerTopClassName);
      }
    };

    addEventListener(window, 'resize', announceFooterHeight);
    announceFooterHeight();
  });
});
