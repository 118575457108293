var $class="se2--footer-se--footer-links",$name="FooterSE/footerLinks",$path="app/components/FooterSE/partials/footerLinks/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

import isMobileBreakpoint from 'app/utilities/isMobileBreakpoint';
import setTabIndex from 'app/utilities/setTabIndex';

import { css, keyNames } from 'configs';

import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';

const linksTitleContainerClassName = 'title-container';
const linksContainerClassName = 'links-list';
const rotateClassName = 'rotate';

export default shell.registerComponent($this, ({ addEventListener, mount }) => {
  mount((element) => {
    const linksTitleContainerElement = element.querySelector(`.${linksTitleContainerClassName}`);
    const linksContainerElement = element.querySelector(`.${linksContainerClassName}`);
    const linksListElement = linksTitleContainerElement.nextElementSibling;
    const menuItemElements = linksListElement.querySelectorAll('.card-link');

    const toggleLinkRole = (link) => {
      setTabIndex(link, !isMobileBreakpoint());

      if (isMobileBreakpoint()) link.setAttribute('role', 'menuitem');
      else link.removeAttribute('role');
    };

    const toggleLinkContainerRole = (linkContainer) => {
      if (isMobileBreakpoint()) linkContainer.setAttribute('role', 'menu');
      else linkContainer.removeAttribute('role');
    };

    if (isMobileBreakpoint()) linksTitleContainerElement.setAttribute('tabindex', 0);

    const toggleMenuItems = () => {
      const classAdded = linksListElement.classList.toggle(css.classNames.show);
      linksTitleContainerElement.classList.toggle(rotateClassName);
      linksTitleContainerElement
        .setAttribute('aria-expanded', classAdded.toString());
    };

    const onKeyDown = (e) => {
      if (!isMobileBreakpoint()) return;
      const { code } = e;
      if (code === keyNames.tab) {
        linksTitleContainerElement.classList.remove(rotateClassName);
        linksListElement.classList.remove(css.classNames.show);
        linksTitleContainerElement.setAttribute('aria-expanded', 'false');
      } else if (code === keyNames.enter || code === keyNames.space) {
        e.preventDefault();
        toggleMenuItems();
      }
      if (code === keyNames.arrowDown || code === keyNames.arrowUp) {
        e.preventDefault();
        const activeMenuItemElementIndex = indexOf(menuItemElements, document.activeElement);
        const indexOfElementToFocus = (
          // eslint-disable-next-line no-nested-ternary
          code === keyNames.arrowDown
            ? activeMenuItemElementIndex === menuItemElements.length - 1
              ? 0
              : activeMenuItemElementIndex + 1
            : activeMenuItemElementIndex === 0
              ? menuItemElements.length - 1
              : activeMenuItemElementIndex - 1
        );
        menuItemElements[indexOfElementToFocus].focus();
      }
    };

    addEventListener(window, 'resize', () => {
      setTabIndex(linksTitleContainerElement, isMobileBreakpoint());

      if (!isMobileBreakpoint()) {
        linksTitleContainerElement.classList.remove(rotateClassName);
        linksListElement.classList.remove(css.classNames.show);
      }

      forEach(menuItemElements, toggleLinkRole);
      toggleLinkContainerRole(linksContainerElement);
    });

    forEach(menuItemElements, toggleLinkRole);
    toggleLinkContainerRole(linksContainerElement);

    addEventListener(
      linksTitleContainerElement,
      'click',
      () => {
        if (!isMobileBreakpoint()) return;
        toggleMenuItems();
      },
    );

    addEventListener(
      linksTitleContainerElement.parentElement,
      'keydown',
      onKeyDown,
    );
  });
});
