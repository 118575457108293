var $class="se2--footer-se--subscribe-form",$name="FooterSE/subscribeForm",$path="app/components/FooterSE/partials/subscribeForm/index.js",$this={$class,$name,$path,};import onFormSubmitMessage from 'app/components/utilities/onFormSubmitMessage';
import validateFormElements from 'app/components/utilities/validateFormElements';
import sha256 from 'app/utilities/sha256';

import analytics from 'app/modules/analytics';
import shell from 'app/modules/shell';

import { css, messages } from 'configs';
import {
  footerCheckboxClassName,
  footerCheckboxContainerSelector,
  footerChinaCheckboxClassName,
  inputCheckboxSelector,
} from 'app/components/FooterSE/config';

export default shell.registerPartial($this, ({ addEventListener, mount, subscribeToMessage }) => {
  let form = null;

  const trackAnalytics = async () => {
    const formId = form.id;
    const formObj = Object.fromEntries(new FormData(form));
    const formEmail = formObj.Email || formObj.email || formObj.EmailOrPhone || formObj.emailOrPhone;

    let userHashedEmail;
    await sha256(formEmail).then((hex) => { userHashedEmail = hex; });

    analytics.trackNewsletterSubscription({ siteLocationKey: 'Footer' });
    analytics.trackContactSubmit({
      formId,
      userHashedEmail,
      formObj,
    });
  };

  mount((element) => {
    form = element.querySelector('form');
    const footerCheckboxContainerElement = form
      .querySelector(footerCheckboxContainerSelector);
    const footerInputCheckboxElement = form
      .querySelector(`${footerCheckboxContainerSelector} ${inputCheckboxSelector}`);
    const submitButtonElement = form
      .querySelector(`.${css.classNames.submitButton}`);

    const formAction = form.getAttribute('action');

    const disableForm = (formElement) => {
      if (!formElement?.elements) return;

      Array.from(formElement.elements).forEach((subformElement) => {
        subformElement.disabled = true;
      });
    };

    // Disable the form when form action (postDataUrl) is empty.
    if (!formAction) {
      disableForm(form);
    }
    // eslint-disable-next-line no-return-assign
    const enableSubmitIfCheckboxPresents = () => submitButtonElement
      .disabled = !(submitButtonElement && footerInputCheckboxElement?.checked);

    const showCheckboxIfCheckboxPresents = ({ resolved, recaptchaId }) => {
      if (!form.querySelector(`#${recaptchaId}`) || !footerCheckboxContainerElement) return;
      if (resolved === true && footerCheckboxContainerElement) {
        footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      }
    };

    if (footerCheckboxContainerElement?.classList.contains(footerCheckboxClassName)) {
      subscribeToMessage(messages.recaptcha, showCheckboxIfCheckboxPresents);
    } else if (footerCheckboxContainerElement?.classList.contains(footerChinaCheckboxClassName)) {
      footerCheckboxContainerElement.classList.remove(css.classNames.hide);
      submitButtonElement.disabled = true;
    }

    subscribeToMessage(
      messages.formSubmit,
      ({ formId, status }) => onFormSubmitMessage(form, formId, status, trackAnalytics),
    );

    addEventListener(footerInputCheckboxElement, 'click', enableSubmitIfCheckboxPresents);
    addEventListener(form, 'submit', validateFormElements);
    addEventListener(submitButtonElement, 'click', () => validateFormElements(form));
  });
});
